import React from 'react';

import payCard_1 from '../../access/img/payCard_1.svg';
import payCard_2 from '../../access/img/payCard_2.svg';
import payCard_3 from '../../access/img/payCard_3.svg';
import {NavLink} from "react-router-dom";
import Dropdown from '../module/Dropdown/Choose/dropdown';
import Page from "../module/include/Page/Page";

class cost extends React.Component {
    state = {
        title: 'Cost'
    };

    render() {
        const chooses1 = [{'id': '1', 'value': 'Physiotherapy 40.00 GBR'}];
        const chooses2 = [{'id': '1', 'value': 'GBR'}];
        return (
            <Page title={this.state.title}>
                <section className="cost">
                    <div className="container">
                        <h2 className="sectionTitle">Cost</h2>
                        <strong>Physiotherapy:</strong>
                        <div className="text">
                            <p>The cost of treatment is £40 for the consultation, and £40 for subsequent treatments. We charge £40 flat rate for all insurance. Treatment usually lasts up to 30 minutes. Should you require an hour, our charge is £70.</p>
                            <p>Please note if you wish to pay by credit card we add a £3 per session surcharge.</p>
                        </div>
                    </div>
                    <div className="container">
                        <strong>Pilates:</strong>
                        <div className="text">
                            <p>£15 per hour. Classes are for a maximum of 4 people.</p>
                        </div>
                    </div>
                    <div className="container">
                        <strong>Acupuncture:</strong>
                        <div className="text">
                            <p>This is included for free with your physiotherapy if you request it.</p>
                        </div>
                    </div>
                    <div className="container">
                        <strong>Osteopathy:</strong>
                        <div className="text">
                            <i>Kay Tomlinson</i>
                            <p>Consultation and Treatment 60 Minutes £45.00</p>
                            <p>Treatment 30 Minutes £40.00</p>
                        </div>
                    </div>
                    <div className="container">
                        <strong>Insurance:</strong>
                        <div className="text">
                            <p>
                                PLEASE REMEMBER YOUR INSURANCE IS YOUR RESPONSIBILITY. If the insurance company does not pay, you will be responsible for any excess or payment.
                                If this happens we will request payment from yourself and issue a receipt so that you can request payment direct from the insurance company.
                            </p>
                        </div>
                    </div>
                </section>
            </Page>);
    }
}

export default cost;