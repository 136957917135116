import React from 'react';
import Page from "../module/include/Page/Page";

import pilates1 from "../../access/img/pilates/Kay-Pilates-1-288x300.jpg"
import pilates2 from "../../access/img/pilates/Kay-Pilates-2-288x300.jpg"
import pilates3 from "../../access/img/pilates/Kay-Pilates-3-288x300.jpg"
import pilates4 from "../../access/img/pilates/Kay-Pilates-9-288x300.jpg"
import pilates5 from "../../access/img/pilates/Kay-Pilates-10-288x300.jpg"


class pilates extends React.Component {
    state = {
        title: 'Pilates'
    };
    render() {
        return (<Page title={this.state.title}>
            <section className="pilates">
                <div className="container">
                    <h2 className="sectionTitle">Pilates</h2>
                    <h3 className="sectionSubtitle">WHY DO PILATES?</h3>
                    <p>
                        Through numerous studies it has been found that body movements are generated from the ‘core’ – a combination of deep spinal muscles attaching to the spine and abdominal wall creating a ‘muscular corsette’.
                    </p>
                    <p>
                        When we suffer back pain, the larger outer back muscles spasm, working harder while the deeper stabilising muscles work less.
                        This leads to compression of joints! Osteopaths and physiotherapists treat these joint restrictions, however the deep stabilising muscles remain weak.
                        This is why starting a rehabilitation programme such as Pilates is essential to correct these muscular imbalances, helping to prevent reoccurrences of back pain.
                    </p>
                    <div className="center">
                        <img src={pilates1} />
                        <img src={pilates2} />
                    </div>
                </div>
                <br/>
                <div className="container">
                    <h3 className="sectionSubtitle">WHAT IS PILATES?</h3>
                    <p>
                        The slow controlled flowing movement of Pilates primarily strengthens the ‘core’ muscles that stabilize and support the spine, realign the body and correct postural problems
                    </p>
                    <p>
                        The deep stabilising muscles activate most effectively in a neutral spinal position, important for protection of spinal discs, joints and ligaments.
                        This rehabilitation exercise programme focuses on optimising these load-bearing relationships, activating the correct muscles and improving muscle imbalances.
                    </p>
                    <p>
                        <i>Pilates can help:</i>
                        Back pain
                        Neck pain & Headaches
                        Tension & Stress
                        Postural problems
                        Stiffness & Tight muscles
                        And much more…
                    </p>
                    <div className="center">
                        <img src={pilates3} />
                        <img src={pilates4} />
                    </div>
                </div>
                <br/>
                <div className="container">
                    <h3 className="sectionSubtitle">ABOUT KAY</h3>
                    <p>
                        Kay Tomlinson is a qualified Pilates instructor. After a thorough osteopathic assessment initially, looking at posture, flexibility and spinal range of motion Kay can instruct on specific exercises to help strengthen and mobilise according to your individual body needs.
                    </p>
                    <p>
                        Kay trained at The British College of Osteopathic Medicine and qualified in both Osteopathy and Naturopathy.
                        She is also qualified in modern acupuncture/ dry needling, which is used for some musculo skeletal problems.
                        Kay will use cranial osteopathy in some treatments, especially when treating infants and babies.
                    </p>
                    <p>Kay Tomlinson BSc Hons (Ost) Med D.O. N.D.</p>
                </div>
                <br/>
                <div className="container availability">
                    <div className="block1">
                        <h3 className="sectionSubtitle">PRICES AND AVAILABILITY</h3>
                        <p>Small Pilates classes limited to 4 people for 1 hour are available at Sutton Physio and cost £15 per session.</p>
                        <p>Available Monday 2-9pm</p>
                        <p>Osteopathy <br/> Initial Assessment (45 Mins) £45 <br/> Subsequent Treatment (30 Mins) £40</p>
                        <p>
                            Pilates Class (60 minutes) £15<br/>
                            One to One Pilates session (60 minutes) £50
                        </p>
                    </div>
                    <div className="center block2">
                        <img src={pilates5} />
                    </div>
                </div>
            </section>
        </Page>);
    }
}
export default pilates;