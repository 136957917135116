import React from 'react';
import Page from "../module/include/Page/Page";
import {NavLink, Route} from "react-router-dom";
import pilates1 from "../../access/img/employees/kay-150x150.jpg"
import pilates2 from "../../access/img/employees/Steve-Photo-Medium-150x150.png"


class osteopathy extends React.Component {
    state = {
        title: 'Osteopathy'
    };
    render() {
        return (<Page title={this.state.title}>
            <section className="pilates">
                <div className="container">
                    <h2 className="sectionTitle">Osteopathy</h2>
                    <h3 className="sectionSubtitle">Our Osteopaths:</h3>
                    <div className="center block">
                        <div><NavLink to={'/employees/id/3'}><img src={pilates1} /></NavLink></div>
                        <div><NavLink to={'/employees/id/2'}><img src={pilates2} /></NavLink></div>
                    </div>
                    <p>
                        When you get injured or feel aches and pains anywhere in your body it is essential for the problem to be understood.
                        How did the injury occur and what has been damaged? How has the body adapted and what is preventing the return to health?
                    </p>
                    <p>
                        Osteopathy is a manual therapy that has scientific and evidence based research to back up the subjective benefits mentioned by patients.
                        It is the study of the body’s anatomy, physiology and function.
                        Osteopaths use their knowledge in combination with their accurate palpation to diagnose and treat any area of the body for any type of musculoskeletal, visceral or cranial problem.
                    </p>
                    <p>Osteopathy aims to return function back to the body using key principals such as:</p>
                    <p></p>
                    <ul className="osteopathy" type="disc | circle | square">
                        <li>The Body is a Unit</li>
                        <li>The Body can heal itself, osteopaths simply remove obstacles in it’s way</li>
                        <li>The Body can adapt and compensate up to a point, after this injury occurs</li>
                    </ul>
                </div>
                {/*<br/>*/}
                <div className="container">
                    <h3 className="sectionSubtitle">How do osteopaths treat?</h3>
                    <p>
                        They start by taking a complete medical history. This will involve questions about the pain you are feeling at the moment and also questions about your
                        general health to make sure that osteopathy is the best treatment for you and to judge whether a referral to your GP or for imaging (such as X-rays/MRIs) are needed.
                        You will then dress down to your underwear or something similar.
                    </p>
                    <p>
                        From the first moment you visit the osteopathy team at Surrey Physio our focus is always you. Our questioning, treatment and aims are all specific to you and your problem.
                        You will be consulted in our private treatment rooms and receive the best treatment and professional advice.
                    </p>
                </div>
                <br/>
                <div className="container">
                    <h3 className="sectionSubtitle">Why might I go and see an osteopath?</h3>
                    <p>
                        Even though the first thing that may spring to mind is that “osteopaths treat backs” – which they certainly do very well.
                        However, osteopaths also help conditions such as minor sports injuries, circulatory problems, cramp, frozen shoulder, neuralgia, and rheumatic pain to list just a few.
                    </p>
                    <ul className="osteopathy">
                        <li>MECHANICAL NECK PAIN</li>
                        <li>ACUTE & CHRONIC BACK PAINy</li>
                        <li>SCIATICA</li>
                        <li>ARTHRITIC PAIN</li>
                        <li>HEADACHES ARISING FORM THE NECK</li>
                        <li>JOINT PAINS</li>
                        <li>POSTURAL PROBLEMS</li>
                    </ul>
                  <p>
                      Structural osteopathy treatment involves gentle, manual techniques – easing pain, reducing swelling and improving mobility.
                      Often, this involves manipulation, which can result in an audible ‘crack’ which is simply the sound of gas bubbles popping in the fluid of the joints.
                  </p>
                    <strong>To contact our team, call 0208 643 8000.</strong>
                </div>
            </section>
        </Page>);
    }
}
export default osteopathy;