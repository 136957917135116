import React from 'react';
import Page from "../module/include/Page/Page";

import pilates1 from "../../access/img/pilates/Kay-Pilates-1-288x300.jpg"
import pilates2 from "../../access/img/pilates/Kay-Pilates-2-288x300.jpg"
import pilates3 from "../../access/img/pilates/Kay-Pilates-3-288x300.jpg"
import pilates4 from "../../access/img/pilates/Kay-Pilates-9-288x300.jpg"
import pilates5 from "../../access/img/pilates/Kay-Pilates-10-288x300.jpg"


class pilates extends React.Component {
    state = {
        title: 'Pilates'
    };
    render() {
        return (<Page title={this.state.title}>
            <section className="pilates">
                <div className="container">
                    <h2 className="sectionTitle">Physiotherapy</h2>
                    <h3 className="sectionSubtitle">What is Physiotherapy?</h3>
                    <p>
                        Physiotherapy is a medical based health care profession that uses hands on treatment techniques,
                        electrotherapy and exercise therapy in restoring human movement and function.
                    </p>
                    <p>
                        Physiotherapy is governed by research and evidence based practice ensuring the most appropriate and safe techniques are utilised for the treatment of musculoskeletal problems.
                    </p>
                </div>
                <div className="container availability">
                    <h3 className="sectionSubtitle">What to expect?</h3>
                    <p>
                        Your Physiotherapist will first take a detailed history of your condition together with any relevant past medical history.
                        The initial assessment will last up to one hour and subsequent treatment sessions will last thirty minutes.
                    </p>
                    <p>
                        A physical assessment will then be undertaken to determine the clinical diagnosis of your problem.
                        This will involve an examination of the area which will incorporate moving and palpating the area and any specific testing that is required.
                    </p>
                    <p>
                        The physiotherapist will then discuss with you a proposed treatment plan and goals, give you an estimate as to the length of treatment required and the proposed outcome of your treatment.
                    </p>
                    <p>
                        Every opportunity will be given to you to ask questions about your condition and your physiotherapist will provide you with advice regarding the management and future injury prevention.
                    </p>
                    <p>
                        To examine you appropriately, shorts and vest top will be required depending on the area involved.
                        If your problem is sports related bringing your sports equipment with you will help your physiotherapist to analyse your problem more thoroughly.
                    </p>
                    <p>
                        Please bring any scans (MRI, X-rays) or letters from your medical specialist with you.
                    </p>
                </div>
            </section>
        </Page>);
    }
}
export default pilates;