import React from 'react';
import team from '../../access/img/team.png';
import addImg from '../../access/img/addImg.svg';
const  HomeBlock5 = () => {
    return <section className="reviews">
        <div className="container">
            <h2 className="sectionTitle">What our patients say</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="review">
                        <p>
                            Many thanks for the Physio carried out on me over the last 5 weeks. It really has helped me immensely. A personal thanks to Jamie Wilson who kept me informed at all times on how I was progressing and the exercises he recommended for me really did make a huge difference. Many Thanks.
                        </p>
                        <div className="author">John Fraser</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="review">
                        <p>
                            I would like to thank Jamie for being a superb physio. He said I could call him to discuss my return to playing football at any time, to make sure I come back and not cause any further injury, which goes beyond what I would expect. Please pass on my thanks, and regards to Jamie and the rest of the team, and his manager too, for the excellent service.
                        </p>
                        <div className="author">John Simmonds</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
export default HomeBlock5;