import React from 'react';
import Page from "../module/include/Page/Page";

class Testimonials extends React.Component {
    state = {
        title: 'Testimonials'
    };

    render() {
        return (<Page title={this.state.title}>
            <section className="testimonials">
                <div className="container">
                    <h2 className="sectionTitle">Testimonials</h2>
                    <div className="text">
                        Thanks for your email and the exercise programmes. I would like to extend my gratitude to John for being so thorough with his prognosis of my varying injuries and his synopsis for the way forward. I will endeavour to correct as much as I can of my body within the constraints of my condition, hopefully with John’s professional advice we will improve my fitness and ability to resist injury in the future.
                    </div>
                    <strong>Fario Marsmari</strong>
                </div>

                <div className="container">
                    <div className="text">
                        Thanks for your email and the exercise programmes.
                        I would like to extend my gratitude to John for being so thorough with his prognosis of my varying injuries and his synopsis for the way forward.
                        I will endeavour to correct as much as I can of my body within the constraints of my condition,
                        hopefully with John’s professional advice we will improve my fitness and ability to resist injury in the future.
                    </div>
                    <strong>Jennifer Corcoran</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I was recently referred by my GP Dr. Brendan Hudson of 83, Grove Road, Sutton, SM1 2BD for physio treatment on a 'painful arc' condition and related problems in my right shoulder. Having completed three sessions with your colleague Jon Davis I am happy to say that excellent progress has been made - resulting (a) in relief of pain and (b) in far greater flexibility of my shoulder than I would have thought possible to attain in so short a time. I feel that with Jon's help I am well on the way to gaining complete recovery from the problem.
                        From the first moment that I entered Surrey Physio I got the feeling that I was in the care of a highly professional organisation. The welcoming manner in which I was received at reception got me off to a great start, the clear emphasis on punctuality and then the outstanding service that I received from Jon exceeded all my expectations. Jon's experience and expertise enabled him quickly to assess my condition and his skill in rectifying what was wrong is proving to be a huge boost to me as someone who had suffered this problem for many months.
                        I am delighted to be able to say that in the light of my prior experience with comparable organisations in this area, Surrey Physio are 'best of breed' by far. With my grateful thanks and with kind regards.
                    </div>
                    <strong>Finbarr O'Donovan</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I was impressed with my first visit to Surrey Physio, I went in on time to my appoinment and found the staff very pleasant.  When I next visit my Doctor I will tell him how good the service was I received.
                    </div>
                    <strong>Mary Robson</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I was referred to Wallington Physio through my GP. I was very impressed at how quickly I was offered an appointment, it is also great that you offer late evening appointments too, because time away from work, especially at the moment is not appreciated. I have only been for one appointment with Faith Cripps, who was great and seemed to find my problem straight away. I look forward to seeing her for my second appointment soon. Service was excellent and punctual. Thank you
                    </div>
                    <strong>Mrs E Compton</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I have recently finished having treatment referred to you by my GP at Shotfield Health Centre. I saw Martin Bull for the most of my physiotherapy and he has really helped me with my pevlis. I’m not completely pain free, that will take time, but Martin gave me excersises to do at home and I’m making progress.
                        Both Martin and Stuart were extremely helpful and very friendly. I would recommend them to anyone.
                    </div>
                    <strong>Cheryl Gough</strong>
                </div>
                <div className="container">
                    <div className="text">
                        Dear Wallington Physio, I would like to thank Mark Archer for the excellent service and the care that was shown to John especially after his short stay in St Helier Hospital after collapsing on me and hurting his back.
                    </div>
                    <strong>Beryl Stephens</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I was seen by my G.P. on a Friday morning and had a phone call the next day asking me to arrange an appointment to see a physio.  I was given one about a week later.   I found the Physio very helpful and he explained the problem with my shoulder and what exercises I should do.  I have a follow up appointment in about two week time.
                        I am very satisfied with the service”
                    </div>
                    <strong>Peter Beckett</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I have been attending your clinic for 5 sessions after a referral from my GP, Dr R Dowding The Knoll Cheam Village.I have been seeing your physiotherapist – Martin, who has been most helpful in trying to remedy my complaint. I have been happy with the service that I have received. Many Thanks
                    </div>
                    <strong>Dorothy Chubb</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I was recently referred to Wallington Physio centre by my GP as I had an on going ankle injury. Once I got there for the first time the staff were so helpful. Starting from the receptionist all the way up to the Physiotherapist who was brilliant. He helped me to over come my problem and his advise was very useful and very helpful. The service was great. I would like to send out a great thank you to the staff at Wallington Physio and most especially to my ex physiotherapist Martin whose surname I did not get. Thank you for such a great service.
                    </div>
                    <strong>Benjamin Bengtsson</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I am writing after yesterday completing four sessions of assessment and treatment with you for my thoracic spine problems.
                        Thank you very much for a thoroughly professional service. I really valued the opportunity of early appointments 7.30-8am, and was seen promptly on each occasion.
                        Most importantly you identified my problem and have made a huge difference over 6 weeks to the point where I feel confident I can manage it ok with the aid of the exercises you have shown me, Thanks again”
                    </div>
                    <strong>Sean Hilton</strong> <i>Deputy Principal and Professor of Primary Care St. George’s, University of London</i>
                </div>

                <div className="container">
                    <div className="text">
                        I was recently treated at the Wallington Physio. I had severe shin pain whenever I ran in football boots which the Physio, Martin thought was due to tight calves. I was very impressed with the professional service that I received and the quickness of appointments and waiting times. The treatment seemed to be going well and when I returned to football 3/4 weeks later I had no problems for 2 weeks and was therefore discharged from the clinic. Many Thanks.
                    </div>
                    <strong>Luke Hales</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I have made 2 visits to Wallington Physio and so far I am very happy with the service you have provided. The receptionist was most welcoming and helpful. Stuart and Martin were very kind and gave me excellent treatment.”
                    </div>
                    <strong>Grace Cooper</strong>
                </div>

            </section>
        </Page>);
    }
}
export default Testimonials;