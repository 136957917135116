import React from 'react';

const  HomeBlock3 = () => {
    return <section className="weUse">
        <div className="container">
            <h2>Sutton Physio – Welcome to our site!</h2>
            <p>
                Sutton Physio is the leading physiotherapy clinic in Sutton and the surrounding areas.
            </p>
            <p>
                We have a small but expert team on hand to help you recover from any injury.
                Our specialities include manual treatment, electrotherapy, exercise rehabilititation, and post-surgical recovery.
                Our main conditions that treat are lower back pain, neck pain, shoulder pain and frozen shoulder, and knee pain.
                We are expert in dealing with arthritis problems that may be having a negative influence on your life, such as knee and hip arthritis.
            </p>
            <p>
                When you come to Sutton Physio, you are guaranteed a high quality and friendly service.
                We know this because our team undergoes extensive training and has extensive experience in many fields of rehabilitation and physiotherapy.
            </p>
            <p>
                We also offer Pilates on a Monday night, and osteopathy on a Monday, Thursday and Saturday.
            </p>
            <p>In the Sutton area we are highly recommended by many GP practices, and this is where we receive most of our referrals.
                We also receive referrals from a number of sports clubs and sports coaches in Sutton and the surrounding areas.
                We have extensive experience with elite athletes, including World class tennis players (and Wimbledon players),
                world class and Olympic sprinters and marathon runners, National weightlifting teams,
                National power lifting teams and many more. We also work with local cricket, football and hockey clubs in the area.
            </p>
            <p>Our location is between Sutton and Carshalton, and our contact number is 020 8 685 6930.</p>
            <p>Thanks for visiting the Sutton Physio site.</p>
        </div>
    </section>
}
export default HomeBlock3;