import React from 'react';
import Page from "../module/include/Page/Page";


class about extends React.Component {
// const  about = () => {
    state = {
        title: 'About us'
    };
    render() {
        return (<Page title={this.state.title}>
            <section className="aboutUs">
                <div className="container">
                    <h2 className="sectionTitle">About us</h2>
                    <div className="aboutUs_content">
                        <div className="aboutUs__advantages">
                            Sutton Physio was established in 2008, a group of like-minded and experienced physiotherapists had a vision to create a top quality physiotherapy clinic.<br/><br/>
                            We have a team of 4 physios, and 2 osteopaths. All of our team have extensive experience in different parts of the body, so we will attempt to direct you to the physio who treats your specific condition.<br/><br/>
                            We run a very happy, friendly clinic. We believe in offering an honest and affordable service. We help about 90% of our clients, but if we cannot help you we will advise you at the earliest opportunity.<br/><br/>
                            Sutton Physio is a nice, small physiotherapy clinic where you can receive expert help.
                        </div>
                        <br/><br/>

                    </div>
                </div>
            </section>
        </Page>);
    }
}
export default about;