import React, {useState} from 'react';

import facebook from '../access/img/facebook.svg';
import twitter from '../access/img/twitter.svg';
import instagram from '../access/img/instagram.svg';
import logo from '../access/img/SUTTON-PHYSIO-LOGo_3.jpg';


import {NavLink} from "react-router-dom";
class Header extends React.Component {

    state = {
        activeClass: false
    };
    handleClick = (button) => {button == 'open' ? this.setState({ activeClass: true }) : this.setState({ activeClass: false }) };

    render() {
        return (
            <header>
                <div className="headerMain">
                    <div className="container">
                        <a href="/" className="headerMain__logo">
                            <img src={logo} alt=""/>
                        </a>
                        <div className="headerMain__contacts">
                            <a href="tel:" className="headerMain__contacts--phone">
                                0208 643 8000
                            </a>
                            <div className="headerMain__contacts--socialnetworks">
                                <a href="https://www.facebook.com/SurreyPhysio">
                                    <img src={facebook} alt=""/>
                                </a>
                                <a href="https://twitter.com/surreyphysio">
                                    <img src={twitter} alt=""/>
                                </a>
                                <a href="https://www.instagram.com/surreyphysio">
                                    <img src={instagram} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="menuIcon" onClick={() => this.handleClick('open')} >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className={this.state.activeClass ? 'headerNav active' : 'headerNav'} >
                    <div className="container" >
                        <div className="menuClose"  onClick={() => this.handleClick('close')}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="headerNav__ul">
                            <li><NavLink to="/" className="selected" onClick={() => this.handleClick('close')}>Welcome</NavLink></li>
                            <li><NavLink to="/about" onClick={() => this.handleClick('close')}>About Us</NavLink></li>
                            <li><NavLink to="/meet_teem" onClick={() => this.handleClick('close')}>Meet the Team</NavLink></li>
                            <li>
                                <a href="" className="down" onClick={() => this.handleClick('close')}>Physio Services</a>
                                <ul className="submenu">
                                    <li><NavLink to="/pilates" onClick={() => this.handleClick('close')}>Pilates</NavLink></li>
                                    <li><NavLink to="/osteopathy" onClick={() => this.handleClick('close')}>Osteopathy</NavLink></li>
                                    <li><NavLink to="/physiotherapy" onClick={() => this.handleClick('close')}>Physiotherapy</NavLink></li>
                                </ul>
                            </li>
                            <li><NavLink to="/cost" onClick={() => this.handleClick('close')}>Cost</NavLink></li>
                            <li><NavLink to="/testimonials" onClick={() => this.handleClick('close')}>Testimonials</NavLink></li>
                            <li><NavLink to="/contact" onClick={() => this.handleClick('close')}>Contact us</NavLink></li>
                        </ul>
                        <div className="headerMain__contacts">
                            <a href="tel:" className="headerMain__contacts--phone">
                                0208 643 8000
                            </a>
                            <div className="headerMain__contacts--socialnetworks">
                                <a href="https://www.facebook.com/SurreyPhysio">
                                    <img src={facebook} alt=""/>
                                </a>
                                <a href="https://twitter.com/surreyphysio">
                                    <img src={twitter} alt=""/>
                                </a>
                                <a href="https://www.instagram.com/surreyphysio">
                                    <img src={instagram} alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}

// var setActive = '';



// handleClick = () => {
//     setActive = 'active';
// };


export default Header;
