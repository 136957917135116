import React, {useEffect, useState} from 'react';
// import './App.css';

import Header from "./components/Header";
import HomePage from  './components/home/home_page';
import Footer from "./components/Footer";
import About from "./components/About/about";

import MeetTeem from "./components/MeetTeem/meetTeem";
import meetTeemEmployee from "./components/MeetTeem/meetTeemEmployee";

import Cost from "./components/Cost/cost";
import Testimonials from "./components/Testimonials/testimonials";
import Olympics from "./components/Olympics/olympics";
import Contact from "./components/Contact/contact";


import Pilates from "./components/Cps/pilates";
import Osteopathy from "./components/Cps/osteopathy";
import Physiotherapy from "./components/Cps/physiotherapy";
import './access/css/libs.css';
import './access/css/main.css';
import './access/css/media.css';

import {
    HashRouter,
    BrowserRouter,
    Route
} from 'react-router-dom';
import Employees from "./components/MeetTeem/employees/employees";
import employees from "./components/MeetTeem/employees/employees";

function App() {

    return (
        <HashRouter>
            <div className="App">
                <Header/>
                <Route exact path='/' component={HomePage} />
                <Route path='/about' component={About} />

                <Route path='/meet_teem/' component={MeetTeem} />
                <Route path='/employees/' component={meetTeemEmployee} />

                <Route path='/pilates' component={Pilates} />
                <Route path='/osteopathy' component={Osteopathy} />
                <Route path='/physiotherapy' component={Physiotherapy} />

                <Route path='/cost' component={Cost} />
                <Route path='/testimonials' component={Testimonials} />
                <Route path='/olympics' component={Olympics} />
                <Route path='/contact' component={Contact} />
                <Footer/>
            </div>
         </HashRouter>
    );

}

export default App;
