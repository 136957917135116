import React from 'react';

import location from '../../access/img/location.svg';
import phone from '../../access/img/phone.svg';
import email from '../../access/img/email.svg';
import calendar from '../../access/img/calendar.svg';
import addimg from '../../access/img/addImg.svg';
import bookonline from "../module/BookOnline/BookOnline";
import BookOnline from "../module/BookOnline/BookOnline";
import Page from "../module/include/Page/Page";

class contact extends React.Component {
    state = {
        title: 'Contact Us'
    };

    render() {
        return (<Page title={this.state.title}>
            <section className="contacts">
                <div className="container">
                    <h2 className="sectionTitle">Contact Us</h2>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contacts__block">
                                {/*<iframe*/}
                                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d622.736212752077!2d-0.1725853780142195!3d51.36731052604355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487607e9d279fdbd%3A0xaea64aba62a7f8d9!2zQ29sZXJpZGdlIEF2ZSwgU3V0dG9uIFNNMSAzUlEsINCS0LXQu9C40LrQvtCx0YDQuNGC0LDQvdC40Y8!5e0!3m2!1sru!2sua!4v1621705716599!5m2!1sru!2sua"*/}
                                {/*    style={{border: 0}} allowFullScreen=""*/}
                                {/*    loading="lazy"></iframe>*/}
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1047.3361179737153!2d-0.17232484307875273!3d51.36631274658619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487607e9b51adee3%3A0x177a29686c5e5750!2sSutton%20Physio!5e0!3m2!1sen!2sua!4v1622100864732!5m2!1sen!2sua"
                                    style={{border: 0}} allowFullScreen=""
                                    loading="lazy"></iframe>
                                <div className="contacts__info">
                                    <div className="info__item">
                                        <div className="info__icon">
                                            <img src={location} alt=""/>
                                        </div>
                                        <div className="info__desc">
                                            <h4>ADDRESS</h4>
                                            <p>2 Coleridge Avenue,</p>
                                            <p>Sutton, Surrey</p>
                                            <p>SM1 3RQ</p>
                                        </div>
                                    </div>
                                    <div className="info__item">
                                        <div className="info__line">
                                            <div className="info__icon">
                                                <img src={phone} alt=""/>
                                            </div>
                                            <div className="info__desc b2">
                                                <h4>Tel:</h4>
                                                <p>020 8 643 8000</p>
                                            </div>
                                        </div>
                                        <div className="info__line">
                                            <div className="info__icon">
                                                <img src={email} alt=""/>
                                            </div>
                                            <div className="info__desc b2">
                                                <h4>Email:</h4>
                                                <p>info@ surreyphysio.co.uk</p>
                                            </div>
                                        </div>
                                        <div className="info__line">
                                            <div className="info__icon">
                                                <img src={email} alt=""/>
                                            </div>
                                            <div className="info__desc b2">
                                                <h4>Manager:</h4>
                                                <p>leila@ surreyphysio.co.uk</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form action="" className="contactUs__form">
                                    <h2 className="form__title">Contact us</h2>
                                    <h3 className="form__subtitle">Fill out the form below to send us a message and we
                                        will get
                                        back to you ASAP.</h3>
                                    <div className="form-group">
                                        <input type="text" placeholder="Full name"/>
                                        <input type="tel" placeholder="Phone number"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" placeholder="Email Address"/>
                                        <input type="text" placeholder="Subject"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea name="msg" placeholder="Message"></textarea>
                                        <button className="btn addImgBtn" type="button">
                                            <img src={addimg} alt=""/>
                                        </button>
                                    </div>
                                    <button className="btn submitBtn">Submit request</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <BookOnline/>
                        </div>
                    </div>
                </div>
            </section>
        </Page>);
    }
}

export default contact;
